import { cx } from '@emotion/css';
import { FC } from 'react';

import { Toast } from '@/components/feedbacks';

import * as styles from './styles';

type PageProps = {
  className?: string;
};

const Page: FC<PageProps> = (props) => {
  const { className, children } = props;

  return (
    <>
      <div className={cx(styles.container, className)}>
        {children}
        <Toast />
      </div>
    </>
  );
};

export default Page;
