import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const container = (disabled: boolean) => css`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 20px 16px;
  border-radius: 8px;
  border: 1px solid ${COLORS.GRAY_30};
  background-color: ${disabled ? COLORS.GRAY_10 : COLORS.WHITE};
  cursor: pointer;

  &:focus-within {
    border: 1px solid ${COLORS.GRAY_100};
  }
`;

export const input = css`
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${COLORS.GRAY_80};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${COLORS.GRAY_10};
    color: ${COLORS.GRAY_60};
  }

  &:read-only {
    cursor: inherit;
  }

  &::placeholder {
    color: ${COLORS.GRAY_50};
  }
`;
