import { cx } from '@emotion/css';
import React from 'react';
import Lottie from 'react-lottie-player';

import { COLORS } from '@/styles/colors';
import LoadingLottie from '@/assets/lotties/loading.json';

import * as styles from './styles';
import { UploaderSize } from './types';
import PhotoIcon from '../../../assets/icons/ic_photo.svg';

export type UploaderProps = {
  uploaderRef: React.RefObject<HTMLInputElement>;
  size: UploaderSize;
  label: string;
  onUpload: React.ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'id' | 'size' | 'type' | 'ref' | 'onChange'
>;

const Uploader: React.FC<UploaderProps> = (props) => {
  const { isLoading, uploaderRef, size, label, accept, onUpload, ...restProps } = props;

  return (
    <label htmlFor={'fileInput'} className={cx(styles.uploader(size))}>
      {isLoading ? (
        <Lottie loop play animationData={LoadingLottie} className={styles.lottie} />
      ) : (
        <>
          <PhotoIcon
            width={24}
            height={24}
            color={COLORS.GRAY_60}
            alt="굿닥 비대면진료 이미지 추가"
          />
          <span className={styles.label(false)}>{label}</span>
          <input
            type={'file'}
            id={'fileInput'}
            ref={uploaderRef}
            className={styles.fileInput}
            accept={accept || '.png, .jpeg, .jpg'}
            onChange={onUpload}
            {...restProps}
          />
        </>
      )}
    </label>
  );
};

export default Uploader;
