import { useCallback, useEffect, useRef } from 'react';

type onClickOutsideProps = {
  onClose: () => void;
  useCapture?: boolean;
};

export function useOnClickOutside(props: onClickOutsideProps) {
  const { onClose, useCapture = false } = props;
  const ref = useRef<HTMLDivElement>(null);

  const clickOutside = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return;
      if (!ref.current.contains(event.target as Node)) onClose();
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('click', clickOutside, useCapture);
    return () => window.removeEventListener('click', clickOutside, useCapture);
  }, [clickOutside, useCapture]);

  return ref;
}
