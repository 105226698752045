import { cx } from '@emotion/css';
import * as React from 'react';

import { useToastState } from '@/context/toast';
import { COLORS } from '@/styles/colors';

import * as styles from './styles';
import { ToastType } from './type';
import InfoIcon from '../../../assets/icons/ic_toast_info.svg';
import NegativeIcon from '../../../assets/icons/ic_toast_negative.svg';
import PositiveIcon from '../../../assets/icons/ic_toast_positive.svg';

export type ToastProps = {
  label: string;
  type: ToastType;
  isLocked?: boolean;
};

const Toast = () => {
  const toast = useToastState();

  if (!toast) {
    return null;
  }

  const { label, type, isLocked = false } = toast;

  const renderToastIcon = () => {
    switch (type) {
      case 'information':
        return (
          <InfoIcon
            width={24}
            height={24}
            color={COLORS.WHITE}
            alt="굿닥 비대면진료 알림"
          />
        );
      case 'success':
        return (
          <PositiveIcon
            width={24}
            height={24}
            color={COLORS.WHITE}
            alt="굿닥 비대면진료 알림"
          />
        );
      case 'error':
      case 'warning':
        return (
          <NegativeIcon
            width={24}
            height={24}
            color={COLORS.WHITE}
            alt="굿닥 비대면진료 알림"
          />
        );
    }
  };

  return (
    <div
      className={cx(
        styles.overlay,
        isLocked ? styles.lockedOverlay : styles.unlockedOverlay
      )}
    >
      <div className={styles.container}>
        {renderToastIcon()}
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  );
};

export default Toast;
