import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const overlay = css`
  position: fixed;
  bottom: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const lockedOverlay = css`
  bottom: 0;
`;

export const unlockedOverlay = css`
  bottom: calc(76px + 12px);
`;

export const container = css`
  display: flex;
  align-items: center;
  margin: 0 24px;
  column-gap: 10px;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 1px rgba(22, 24, 29, 0.02), 0 2px 8px rgba(22, 24, 29, 0.1);
  background-color: ${COLORS.GRAY_70};
  width: calc(640px - 48px);
  @media (max-width: 640px) {
    width: calc(100vw - 48px);
  }
`;

export const label = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${COLORS.WHITE};
`;
