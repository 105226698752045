import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

import { StyleType, Size } from './types';

export const button = (size: Size, styleType: StyleType, disabled: boolean) => css`
  ${getButton()};
  ${getSize(size)};
  ${disabled ? getDisabledStyle(styleType) : getStyleType(styleType)};
`;

const getButton = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

const getSize = (size: Size) => {
  switch (size) {
    case 'full':
      return css`
        height: 56px;
        padding: 16px 20px;
        font-size: 16px;
        line-height: 24px;
        min-width: 100%;
        font-weight: 700;
      `;
    case 'large':
      return css`
        height: 56px;
        padding: 16px 20px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        min-width: 112px;
        font-weight: 700;
      `;
    case 'medium':
      return css`
        height: 48px;
        padding: 12px 16px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 21px;
        min-width: 84px;
        font-weight: 700;
      `;
    case 'small':
      return css`
        height: 36px;
        padding: 8px 12px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 21px;
        min-width: 56px;
        font-weight: 500;
      `;
  }
};

const getDisabledStyle = (type: StyleType) => {
  switch (type) {
    case 'blueFilled':
    case 'blueTonal':
      return css`
        background-color: ${COLORS.GRAY_30};
        color: ${COLORS.GRAY_50};
        &:active {
          background-color: ${COLORS.GRAY_40};
        }
      `;
    case 'grayFilled':
    case 'grayTonal':
      return css`
        background-color: ${COLORS.GRAY_30};
        color: ${COLORS.GRAY_50};
        &:active {
          background-color: ${COLORS.ALPHA_BLACK_05};
          color: ${COLORS.ALPHA_BLACK_15};
        }
      `;
    case 'redFilled':
    case 'redTonal':
      return css`
        background-color: ${COLORS.GRAY_30};
        color: ${COLORS.GRAY_50};
        &:active {
          background-color: ${COLORS.ALPHA_BLACK_05};
          color: ${COLORS.ALPHA_BLACK_15};
        }
      `;
    case 'blueOutlined':
    case 'grayOutlined':
    case 'redOutlined':
    case 'blueColoredOutlined':
      return css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.GRAY_40};
        border: 1px solid ${COLORS.GRAY_30};
        &:active {
          color: ${COLORS.GRAY_50};
          border: 1px solid ${COLORS.GRAY_40};
          background-color: ${COLORS.ALPHA_GRAY_05};
        }
      `;
    case 'yellowFilled':
      return css`
        background-color: ${COLORS.YELLOW_60};
        color: ${COLORS.BLACK};
        &:active {
          background-color: ${COLORS.YELLOW_60};
          color: ${COLORS.BLACK};
        }
      `;
  }
};

const getStyleType = (type: StyleType) => {
  switch (type) {
    case 'blueFilled':
      return css`
        background-color: ${COLORS.BLUE_60};
        color: ${COLORS.WHITE};
        &:active {
          background-color: ${COLORS.BLUE_80};
        }
      `;

    case 'blueTonal':
      return css`
        background-color: ${COLORS.BLUE_20};
        color: ${COLORS.BLUE_60};
        &:active {
          background-color: ${COLORS.BLUE_30};
        }
      `;
    case 'blueOutlined':
      return css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.BLUE_60};
        border: 1px solid ${COLORS.GRAY_30};
        &:active {
          background-color: ${COLORS.GRAY_30};
        }
      `;
    case 'blueColoredOutlined':
      return css`
        color: ${COLORS.BLUE_60};
        border: 1px solid ${COLORS.BLUE_60};
        background-color: ${COLORS.WHITE};
        &:active {
          color: ${COLORS.BLUE_80};
          border: 1px solid ${COLORS.BLUE_80};
          background-color: ${COLORS.ALPHA_GRAY_05};
        }
      `;
    case 'grayFilled':
      return css`
        background-color: ${COLORS.GRAY_80};
        color: ${COLORS.WHITE};
        &:active {
          background-color: ${COLORS.GRAY_90};
        }
      `;

    case 'grayTonal':
      return css`
        background-color: ${COLORS.GRAY_20};
        color: ${COLORS.GRAY_70};
        &:active {
          background-color: ${COLORS.GRAY_30};
        }
      `;
    case 'grayOutlined':
      return css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.GRAY_70};
        border: 1px solid ${COLORS.GRAY_30};
        &:active {
          background-color: ${COLORS.GRAY_30};
        }
      `;
    case 'redFilled':
      return css`
        background-color: ${COLORS.RED_60};
        color: ${COLORS.WHITE};
        &:active {
          background-color: ${COLORS.RED_80};
        }
      `;

    case 'redTonal':
      return css`
        background-color: ${COLORS.RED_20};
        color: ${COLORS.RED_60};
        &:active {
          background-color: ${COLORS.RED_30};
        }
      `;
    case 'redOutlined':
      return css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.RED_60};
        border: 1px solid ${COLORS.GRAY_30};
        &:active {
          background-color: ${COLORS.GRAY_30};
        }
      `;
    case 'yellowFilled':
      return css`
        background-color: ${COLORS.YELLOW_60};
        color: ${COLORS.BLACK};
        &:active {
          background-color: ${COLORS.YELLOW_60};
        }
      `;
  }
};
