import { css } from '@emotion/css';

import { COLORS } from '../../../styles/colors';

export const wrapper = (disabled: boolean) => css`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${COLORS.GRAY_30};
  background-color: ${COLORS.WHITE};
  ${disabled && getDisabledContainer()};

  &:focus-within {
    border: 1px solid ${COLORS.GRAY_80};
  }
`;

export const textarea = css`
  flex: 1;
  width: 100%;
  font-family: inherit;
  border: none;
  outline: none;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${COLORS.GRAY_90};

  &::placeholder {
    color: ${COLORS.GRAY_50};
  }
`;

export const counter = css`
  display: flex;
  justify-content: flex-end;
  column-gap: 2px;
`;

export const getDisabledContainer = () => {
  return css`
    border: 1px solid ${COLORS.GRAY_30};
    background-color: ${COLORS.GRAY_10};
  `;
};
