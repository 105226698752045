import React from 'react';
import { cx } from '@emotion/css';

import { Size, StyleType } from './types';
import * as styles from './styles';

export type ButtonProps = {
  className?: string;
  size?: Size;
  styleType: StyleType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = (buttonProps) => {
  const {
    className,
    children,
    size = 'small',
    styleType,
    disabled = false,
    ...props
  } = buttonProps;

  return (
    <button
      className={cx(styles.button(size, styleType, disabled), className)}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
