export const updatePhoneNumberHyphen = (withoutHyphen: string, phone: string) => {
  if (withoutHyphen.length <= 3) {
    return phone;
  } else {
    let regx = /^(\d{2,3})(\d{1,2})$/;
    if (withoutHyphen.length >= 5 && withoutHyphen.length <= 7) {
      regx = /^(\d{2,3})(\d{3,4})$/;
    } else if (withoutHyphen.length === 8) {
      const match = withoutHyphen.match(/^(\d{4})(\d{4})$/);
      if (match) {
        return `${match[1]}-${match[2]}`;
      }
    } else if (withoutHyphen.length === 10) {
      regx = /^(\d{3})(\d{3})(\d{4})$/;
      if (withoutHyphen.substr(0, 2) === '02') {
        regx = /^(\d{2})(\d{4})(\d{4})$/;
      }
    } else if (withoutHyphen.length >= 9 && withoutHyphen.length <= 11) {
      regx = /^(\d{2,3})(\d{3,4})(\d{3,4})$/;
      if (withoutHyphen.substr(0, 2) === '02') {
        regx = /^(\d{2})(\d{3})(\d{4})$/;
      }
    }
    const match = withoutHyphen.match(regx);
    if (match) {
      return `${match[1]}-${match[2]}${match[3] ? '-' + match[3] : ''}`;
    }
  }
  return phone;
};
