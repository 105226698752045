import React, { ForwardedRef, TextareaHTMLAttributes, forwardRef } from 'react';
import { cx } from '@emotion/css';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';

import * as styles from './styles';

export interface TextareaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'value'> {
  className?: string;
  value?: string;
}

const Textarea = (props: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const {
    className,
    children,
    maxLength,
    disabled = false,
    value = '',
    ...restProps
  } = props;

  return (
    <div className={cx(styles.wrapper(disabled), className)}>
      <textarea
        ref={ref}
        className={styles.textarea}
        disabled={disabled}
        maxLength={maxLength}
        value={value}
        {...restProps}
      />
      {maxLength && (
        <div className={styles.counter}>
          <Text type={'caption_400'} color={COLORS.GRAY_60}>
            {value.length}
          </Text>
          <Text type={'caption_400'} color={COLORS.GRAY_60}>
            {'/'}
          </Text>
          <Text type={'caption_400'} color={COLORS.GRAY_60}>
            {maxLength}
          </Text>
        </div>
      )}
      {children}
    </div>
  );
};

export default forwardRef(Textarea);
