import { css } from '@emotion/css';

export const container = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (min-width: 640px) {
    margin: auto;
    max-width: 640px;
  }
`;
