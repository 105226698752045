import React, { useState } from 'react';

import { COLORS } from '@/styles/colors';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

import Input from '../Input';
import ArrowDown from '../../../assets/icons/ic_arrow_down_light.svg';
import * as styles from './styles';

export type SelectBoxProps = {
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  children: React.ReactNode;
};

const SelectBox: React.FC<SelectBoxProps> = (props) => {
  const { value, children, placeholder, disabled } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectBoxRef = useOnClickOutside({ onClose: () => setIsOpen(false) });

  return (
    <div
      ref={selectBoxRef}
      className={styles.container}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Input
        layoutClassName={styles.boxContainer}
        value={value}
        readOnly={true}
        disabled={disabled}
        placeholder={placeholder}
        rightComponent={
          <ArrowDown
            width={24}
            height={24}
            color={COLORS.GRAY_90}
            className={styles.icon(isOpen)}
            alt="굿닥 비대면진료 화살표"
          />
        }
      />
      {isOpen && <div className={styles.itemsContainer}>{children}</div>}
    </div>
  );
};

export default SelectBox;
