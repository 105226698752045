import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

import { UploaderSize } from './types';

export const uploader = (size: UploaderSize) => css`
  ${getCommonUploader()}
  ${getUploaderSize(size)}
`;

export const draggedUploader = css`
  background: ${COLORS.ALPHA_BLUE_05};
  border: 1px solid ${COLORS.BLUE_40};
  background: ${COLORS.WHITE};
`;

export const label = (isDragging: boolean) => css`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${isDragging ? COLORS.ALPHA_BLUE_90 : COLORS.GRAY_60};
`;

export const fileInput = css`
  display: none;
`;

const getCommonUploader = () => css`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
  background-color: ${COLORS.TRANSPARENT};
  border: 1px solid ${COLORS.GRAY_30};
  border-radius: 6px;
  background: ${COLORS.WHITE};
  cursor: pointer;
`;

export const lottie = css`
  width: 40px;
  height: 40px;
`;

const getUploaderSize = (size: UploaderSize) => {
  switch (size) {
    case 'small':
      return css`
        width: 96px;
        height: 96px;
      `;
    case 'medium':
      return css`
        width: 120px;
        height: 120px;
      `;
    case 'large':
      return css`
        width: 100%;
        height: 120px;
      `;
  }
};
