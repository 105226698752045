import React, { ForwardedRef, forwardRef } from 'react';

import { updatePhoneNumberHyphen } from '@/utils/updatePhoneNumberHyphen';

import Input from '..';
import { InputProps } from '../Input';

export interface PhoneProps extends Omit<InputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Phone = (props: PhoneProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { prefix = '', value = '', onChange = () => undefined, ...restProps } = props;

  const formatPhone = (phone: string) => {
    const withoutHyphenPhoneNumber = phone.replace(/-/g, '');
    if (shouldReturnPrefix(withoutHyphenPhoneNumber)) return prefix;
    return updatePhoneNumberHyphen(withoutHyphenPhoneNumber, phone);
  };

  const shouldReturnPrefix = (phone: string) => {
    if (phone.startsWith(prefix) && phone.length > prefix.length) {
      return false;
    }
    return true;
  };

  const inputPhoneNumber: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(formatPhone(event.target.value), event);
  };

  return (
    <Input
      ref={ref}
      inputMode="tel"
      type="tel"
      maxLength={13}
      value={formatPhone(value)}
      onChange={inputPhoneNumber}
      {...restProps}
    />
  );
};

export default forwardRef(Phone);
