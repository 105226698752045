import { css } from '@emotion/css';

export const container = css`
  position: relative;
`;

export const boxContainer = css`
  padding: 16px;
`;

export const itemsContainer = css`
  position: absolute;
  width: 100%;
  z-index: 10;
`;

export const icon = (isOpen: boolean) => css`
  transition: transform 0.3s ease;
  transform: rotate(0);
  ${isOpen && 'transform: rotate(-180deg);'}
`;
