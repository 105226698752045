import React from 'react';

import Input from '..';
import { InputProps } from '../Input';
import * as styles from './styles';

export type NumberProps = InputProps;

const Number: React.FC<NumberProps> = (props) => {
  return <Input type={'number'} inputClassName={styles.numberInput} {...props} />;
};

export default Number;
