import React, { ForwardedRef, forwardRef } from 'react';
import { cx } from '@emotion/css';

import { textType } from './types';
import * as styles from './styles';
import { AllowKeysForNumberInput } from './constants';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'className'> {
  type?: textType;
  layoutClassName?: string;
  inputClassName?: string;
  children?: React.ReactNode;
  onEnter?: (value?: string | number | readonly string[]) => void;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

const Input = (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    type,
    disabled = false,
    value,
    layoutClassName,
    inputClassName,
    leftComponent,
    rightComponent,
    onEnter,
    ...restProps
  } = props;

  const checkPreventInput = (key: string) => {
    if (key === 'Enter' && onEnter) {
      onEnter(value);
      return false;
    }

    if (type === 'tel' || type === 'number') {
      return !AllowKeysForNumberInput.includes(key);
    }

    return false;
  };

  return (
    <div className={cx(styles.container(disabled), layoutClassName)}>
      {leftComponent}
      <input
        ref={ref}
        value={value}
        disabled={disabled}
        className={cx(styles.input, inputClassName)}
        onKeyDown={(event) => checkPreventInput(event.key) && event.preventDefault()}
        {...restProps}
      />
      {rightComponent}
    </div>
  );
};

export default forwardRef(Input);
